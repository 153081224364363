<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/management") 回上一層
    .col-lg-12
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 基本設定

        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-4
                .form-group.mb-3
                  label 議題分類
                  br
                  .row
                    .col-6
                      multiselect(
                        v-model="settingData.mainType", 
                        :options="$root.newsTypeList", 
                        placeholder="選擇主分類", 
                        label="name", 
                        track-by="id"
                        select-label=""
                      )

                    .col-6
                      multiselect(
                        v-model="settingData.subType",
                        :options="(settingData.mainType && settingData.mainType.sub_types) || []",
                        placeholder="選擇次分類",
                        label="name",
                        track-by="id"
                        select-label="")
              .col-lg-4
                .form-group.mb-3
                  label 
                    span(v-if="tab === 1") 投票開始日
                    span(v-if="tab === 2") PK 開始日
                    span(v-if="tab === 3") 民調開始日
                    span(v-if="tab === 4") 話題開始日
                  .row 
                    .col-8
                      date-picker(v-model="settingData.startDate", append-to-body, lang="en", confirm, placeholder="請選擇日期")
                    .col-4
                      date-picker(v-model="settingData.startTime" format="HH:mm" value-type="format" type="time" placeholder="時 : 分")

              .col-lg-4
                .form-group.mb-3
                  label 
                    span(v-if="tab === 1") 投票截止日
                    span(v-if="tab === 2") PK 截止日
                    span(v-if="tab === 3") 民調截止日
                    span(v-if="tab === 4") 話題截止日
                  .row 
                    .col-8
                      date-picker(v-model="settingData.endDate", append-to-body, lang="en", confirm, placeholder="請選擇日期")
                    .col-4
                      date-picker(v-model="settingData.endTime" format="HH:mm" value-type="format" type="time" placeholder="時 : 分")
    .col-lg-12.mt-2
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 內容設定
            .col-4.title-box.text-right
              b-button-group.tab-group
                b-button.mr-2(variant="primary" :class="{off: tab !== 1}" @click="changeTab(1)") 選擇題
                b-button(variant="primary" :class="{off: tab !== 2}" @click="changeTab(2)") 話題PK
                b-button(variant="primary" :class="{off: tab !== 3}" @click="changeTab(3)") 選前民調
                b-button(variant="primary" :class="{off: tab !== 4}" @click="changeTab(4)") 話題人物

        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-6
                .form-group.mb-3
                  label 
                    span(v-if="tab >= 1 && tab <= 3") 投票封面
                    span(v-if="tab == 4") 話題人物封面
                  br
                  SelectImage(
                    key="voteImage"
                    itemKey="voteImage"
                    type="image"
                    @upDateImage="setVoteImage"
                    :value="settingData.image"
                  )
                  .img-view
                    i.fe-image(v-if="!settingData.image")
                    img(v-if="settingData.image" :src='settingData.image.url' rounded )
            .row
              .col-6
                .form-group.mb-3(v-if="tab == 4")
                  label 
                    span 話題人物姓名
                  br
                  input.form-control(
                    v-model="settingData.name"
                    type="text",
                    placeholder="請輸入人物姓名"
                  )

                .form-group.mb-3
                  label 
                    span(v-if="tab >= 1 && tab <= 3") 標題
                    span(v-if="tab == 4") 話題事件標題
                  br
                  input.form-control(
                    v-model="settingData.title"
                    type="text",
                    placeholder="請輸入標題，限制50個字"
                  )

              .col-6
                .form-group.mb-3(v-if="tab == 4")
                  label 
                    span 話題人物所屬政黨
                  br
                  select.form-control(v-model="settingData.partyId")
                    option(value="0") 不設定政黨
                    option(v-for="( party, index ) in ElectionPartys" :value="party.id") {{ party.name }}

                .form-group.mb-3
                  label TAG
                  br
                  multiselect(
                    v-model="settingData.tags",
                    :options="tagsOptions",
                    @search-change="searchTagList",
                    :multiple="true"
                    placeholder="請輸入 TAG 名稱",
                    label="name",
                    track-by="id"
                    select-label="")
              .col-12.mb-2
                .form-group.mb-3
                  label 
                    span(v-if="tab >= 1 && tab <= 3") 敘述
                    span(v-if="tab == 4") 話題事件敘述
                  br
                  textarea.form-control(
                    v-model="settingData.description",
                    rows="3",
                    type="text",
                    placeholder="請輸入說明，限制200個字"
                  )
              .col-6(v-if="tab === 2 || tab === 4")
                .form-group.mb-3
                  label 同意描述文字(選填)
                  br
                  input.form-control(
                    v-model="settingData.leftAnser.name"
                    type="text",
                    placeholder="請輸入同意描述文字，限制20個字"
                  )

              .col-6(v-if="tab === 2 || tab === 4")
                .form-group.mb-3
                  label 反對描述文字(選填)
                  br
                  input.form-control(
                    v-model="settingData.rightAnser.name"
                    type="text",
                    placeholder="請輸入反對描述文字，限制20個字"
                  )


              .col-12.mb-2(v-if="tab === 1 || tab === 3")
                .form-group.mb-3
                  label 選擇答案數量
                  br
                  .d-sm-flex
                    b-form-radio-group#radio-group-1(v-model='settingData.isMultipleAnsers')
                      b-form-radio(:value="false") 單選
                      b-form-radio(:value="true") 複選( 可複選數量
                        input.ml-1.mr-1(v-model="settingData.anserCount" :disabled="!settingData.isMultipleAnsers || settingData.unlimitAnser" type='number' style="width: 50px; height: 21px;")
                    b-form-checkbox#checkbox-1(v-model="settingData.unlimitAnser" :disabled="!settingData.isMultipleAnsers") 不限制)
              .col-5.mb-2(v-if="tab === 1 || tab === 3")
                .form-group.mb-3
                  label 選項內容設置
                  br
                  draggable(v-model="settingData.ansers", group="people", @start="drag=true", @end="drag=false")
                    .option-item.mb-2(v-for="(option,index) in settingData.ansers" :key="option.id")
                      .col-10.option-item
                        .icon
                          i.fas.fa-grip-lines
                        input.form-control(
                          v-model="option.name"
                          type="text",
                          placeholder="請輸入標題，限制50個字"
                        )
                      .d-flex(v-if="index + 1 === settingData.ansers.length")
                        .ri-menu-add-line.mr-1(style="font-size: 20px;" @click="addNewOne()")
                        .ri-close-circle-line(style="font-size: 20px;" @click="deleteOne(index)")


              .col-12.mb-2(v-if="tab === 1")
                .form-group.mb-3
                  label 投票結果顯示
                  br
                  b-form-group(v-slot='{ ariaDescribedby }')
                    .d-md-flex
                      .mr-2.mb-2.mb-md-0
                        img.option-image(src="@/assets/images/new-use/bar.png" @click="settingData.chartType = 1" :class="{'active': settingData.chartType === 1}")
                        b-form-radio.mt-2(v-model='settingData.chartType' :value='1') 長條圖
                      .mb-2
                        img.option-image(src="@/assets/images/new-use/circle.png" @click="settingData.chartType = 2" :class="{'active': settingData.chartType === 2}")
                        b-form-radio.mt-2(v-model='settingData.chartType' :value='2') 圓餅圖

              .col-12.mb-2(v-if="tab === 1 || tab === 3")
                .form-group.mb-3
                  label 投票頻率
                  br
                  b-form-radio-group#radio-group-2(v-model='settingData.repeatType')
                    //- b-form-radio(:value='1') 不同裝置限投1次
                    b-form-radio(:value='2') 每天1次
                    b-form-radio(:value='3') 每天3次(每次需間隔一小時)
                    b-form-radio(:value='4') 一個帳號僅限一次

      .row.text-center.mt-3
        .col
          b-button.width-lg.mr-1(variant="primary" @click="saveSetting" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}
          b-button.width-lg.mr-1(variant="primary" @click="previewSetting" :disabled="!currentVoteId") 預覽
          b-button.width-lg(variant="danger" @click="removeSetting" :disabled="!currentVoteId") 刪除
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import SelectImage from "@/components/select-image";
import draggable from 'vuedraggable'

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "投票編輯",
      items: [
        {
          text: "投票管理",
          href: "/management",
        },
        {
          text: "投票編輯",
          active: true,
        }
      ],
      currentVoteId: null,
      settingData: {
        name: '',
        partyId: '0',
        mainType: null,
        subType: null,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        type: 1, // 選擇 or PK
        image: null,
        title: '',
        description: '',
        tags: [],
        unlimitAnser: false, // 是否不限制答案數量
        isMultipleAnsers: false, // 是否為多選，預設單選
        anserCount: null, // 可選擇答案數 1: 單選, 999: 無限, N: 其他自定義的數量
        ansers: [
          { id: 1, name: '' },
          { id: 2, name: '' },
          { id: 3, name: '' }
        ],
        leftAnser: { name: "" },
        rightAnser: { name: "" },
        chartType: 1, // 圖票類型 1: 長條圖, 2: 圓餅圖
        repeatType: 4 // 投票頻率 0: 未設定, 1: 不同裝置限投一次, 2: 每天一次, 3: 每天三次, 4: 一個帳號僅限一次
      },
      ElectionPartys: [],
      tagsOptions: [],
      saving: false,
      tab: 1,
    };
  },
  computed: {

  },
  mounted () {
    this.currentVoteId = this.$route.params?.votingId || null
    if (this.currentVoteId) {
      this.getVoteSetting()
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    SelectImage,
    draggable
  },
  watch: {
    'settingData.isMultipleAnsers' (val) {
      if (!val) {
        this.settingData.anserCount = null
        this.settingData.unlimitAnser = false
      }
    },
    'settingData.unlimitAnser' (val) {
      if (val) {
        this.settingData.anserCount = null
      }
    }
  },
  created () {
    this.getElectionPartys()
  },
  methods: {
    getVoteSetting () {
      const vm = this
      this.$root.apis.getVote(
        this.currentVoteId,
        function (_response) {
          const result = _response.body.data
          vm.settingData = {
            mainType: vm.$root.newsTypeList.find(_type => _type.id === result.main_type.id),
            subType: result.sub_type,
            startDate: vm.$root.common.getNowTime('Object', result.start_date),
            startTime: vm.$root.common.getNowTime('time', result.start_date),
            endDate: vm.$root.common.getNowTime('Object', result.end_date),
            endTime: vm.$root.common.getNowTime('time', result.end_date),
            // 選擇 or PK
            type: result.type || 1,
            image: { id: "", url: result.image },
            title: result.title,
            description: result.description,
            tags: result.tags,
            // 是否不限制答案數量
            unlimitAnser: result.anser_count === 999,
            // 是否為多選，預設單選
            isMultipleAnsers: result.anser_count > 1,
            // 可選擇答案數 1: 單選, 999: 無限, N: 其他自定義的數量
            anserCount: result.anser_count > 1 ? result.anser_count : null,
            // 多選答案清單
            ansers: result.ansers.length > 0 ? result.ansers : [{ id: 1, name: '' }, { id: 2, name: '' }, { id: 3, name: '' }],
            leftAnser: { name: result.left_anser?.name || '' },
            rightAnser: { name: result.right_anser?.name || '' },
            // 圖票類型 1: 長條圖, 2: 圓餅圖
            chartType: result.chart_type ?? 1,
            repeatType: result.repeat_type ?? 1,
            // 處理話題人物欄位
            name: result.user_name,
            partyId: result.party_id,
          }
          vm.tab = result.type || 1
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
        }
      )
    },
    // 取得政黨清單
    getElectionPartys () {
      let vm = this
      this.$root.apis.getElectionPartys({}, function (_response) {
        vm.ElectionPartys = _response.body.data
      })
    },
    // 設定封面圖片
    setVoteImage (_image) {
      this.settingData.image = _image
    },
    // 搜尋符合的標籤
    searchTagList (_text) {
      let vm = this
      this.$root.apis.getFastTagList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.tagsOptions = _response.body.data.items
      })
    },
    changeTab (_status) {
      this.tab = _status
      this.settingData.type = _status
    },
    addNewOne () {
      const newOne = {
        id: this.settingData.ansers.length + 1,
        name: ''
      }
      this.settingData.ansers.push(newOne)
    },
    deleteOne (_index) {
      if (this.settingData.ansers.length > 2) {
        this.settingData.ansers.splice(_index, 1)
      }
    },
    checkRequired () {
      const basicRequired = ['type', 'mainType', 'subType', 'startDate', 'startTime', 'endDate', 'endTime', 'image', 'title']
      if (basicRequired.some(_item => !this.settingData[_item])) {
        this.$root.common.showErrorDialog("請確定必填欄位都有填寫完成喔！")
        return false
      }
      // 選擇題
      if (this.settingData.type === 1) {
        // 複選且有限制答案數量
        if (this.settingData.isMultipleAnsers && !this.settingData.unlimitAnser && (!this.settingData.anserCount || this.settingData.anserCount < 2)) {
          this.$root.common.showErrorDialog("請填寫複選數量（數量必須大於 1）")
          return false
        }
        // 答案數量大於 2
        if (this.settingData.ansers.filter(_item => _item.name).length < 2) {
          this.$root.common.showErrorDialog("選項不得少於 2 則")
          return false
        }
      }
      return true
    },
    saveSetting () {
      if (!this.checkRequired()) return
      const saveData = {
        type: this.settingData.type,
        maintype_id: this.settingData.mainType.id,
        subtype_id: this.settingData.subType.id,
        start_date: this.$root.common.getNowTime('date', this.settingData.startDate),
        start_time: this.settingData.startTime,
        end_date: this.$root.common.getNowTime('date', this.settingData.endDate),
        end_time: this.settingData.endTime,
        image: this.settingData.image.url,
        title: this.settingData.title,
        description: this.settingData.description,
        tags: this.settingData.tags.map(_item => _item.id),
        unlimit_anser: this.settingData.unlimitAnser,
        anser_count: this.settingData.isMultipleAnsers
          ? this.settingData.unlimitAnser ? 999 : this.settingData.anserCount
          : 1,
        ansers: this.currentVoteId
          ? this.settingData.ansers.map(_item => typeof (_item.id) === 'string' ? _item : { name: _item.name })
          : this.settingData.ansers.map(_item => ({ name: _item.name })),
        left_anser: this.settingData.leftAnser,
        right_anser: this.settingData.rightAnser,
        chart_type: this.settingData.chartType,
        repeat_type: this.settingData.repeatType,
        user_name: this.settingData.name,
        party_id: this.settingData.partyId
      }
      this.currentVoteId ? this.updateVote(saveData) : this.createVote(saveData)
    },
    createVote (_saveData) {
      this.saving = true
      const vm = this
      this.$root.apis.createVote(_saveData,
        function (_response) {
          vm.saving = false
          vm.$root.common.showSingleNotify("建立成功")
          vm.currentVoteId = _response.body.data
          vm.$router.push({ path: `/management-setting/${_response.body.data}` })
          vm.getVoteSetting()
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
          vm.saving = false
        }
      )
    },
    updateVote (_saveData) {
      this.saving = true
      const vm = this
      this.$root.apis.updateVote(this.currentVoteId, _saveData,
        function (_response) {
          vm.saving = false
          vm.getVoteSetting()
          vm.$root.common.showSingleNotify("更新成功")
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
          vm.saving = false
        }
      )
    },
    previewSetting () {
      window.open(this.$root.site_uri + '/polls/' + this.currentVoteId)
    },
    removeSetting () {
      const vm = this
      this.$root.common.confirmAction("刪除投票", "確定要刪除投票？", "刪除", "再想一下",
        function (_result) {
          vm.$root.apis.removeVote(vm.currentVoteId,
            function (_response) {
              vm.$router.push({ path: '/management' })
            },
            function (_error) {
              console.error(_error)
              vm.$root.common.showErrorDialog(_error.body.data)
            })
        }
      )
    }
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  // background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  img
    width: 100%
    height: 100%
    object-fit: cover
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%
.form-label-row
  display: flex
  gap: 14px
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center

.option-item
  display: flex
  gap: 10px
  width: 100%
  align-items: center
  ::v-deep .custom-control-label::after
    top: 45%
  ::v-deep .custom-control-label::before
    top: 45%

.option-image
  width: 283px
  height: 198px
  object-fit: cover
  border: 1px solid #BDBDBD
  border-radius: 2px
  cursor: pointer
  &.active
    border: 1px solid #1e40b0
</style>
